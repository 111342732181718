/* eslint-disable linebreak-style */
/* eslint-disable no-use-before-define */
export default function setUpStore() {
  const collectionsToShow = [
    {
      name: 'UniQ hundafóður',
      collectionId: 267998429392,
      divId: 'UniQ_hundafodur',
    },
    {
      name: 'UniQ olíur og bætiefni',
      collectionId: 267998298320,
      divId: 'UniQ_oliur_og_baetiefni',
    },
    {
      name: 'Power Poofer Air Launcher',
      collectionId: 267998396624,
      divId: 'Power_Poofer_Air_Launcher',
    },
    {
      name: 'Siccaro',
      collectionId: 268012126416,
      divId: 'Siccaro',
    },
  ];

  collectionsToShow.forEach((collection) => {
    showCollection(collection.divId, collection.collectionId);
  });
}

function showCollection(divId = null, collectionId = null) {
  const scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
  if (window.ShopifyBuy) {
    if (window.ShopifyBuy.UI) {
      ShopifyBuyInit();
    } else {
      loadScript();
    }
  } else {
    loadScript();
  }
  function loadScript() {
    const script = document.createElement('script');
    script.async = true;
    script.src = scriptURL;
    (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
    script.onload = ShopifyBuyInit;
  }
  function ShopifyBuyInit() {
    // eslint-disable-next-line no-undef
    const client = ShopifyBuy.buildClient({
      domain: 'hrafnsvik.myshopify.com',
      storefrontAccessToken: '2396bae206883cf11bb92131f8c250d4',
    });
    // eslint-disable-next-line no-undef
    ShopifyBuy.UI.onReady(client).then((ui) => {
      ui.createComponent('collection', {
        id: collectionId,
        node: document.getElementById(divId),
        moneyFormat: '{{amount_no_decimals_with_comma_separator}} kr.',
        options: {
          product: {
            styles: {
              product: {
                '@media (min-width: 601px)': {
                  'max-width': 'calc(25% - 20px)',
                  'margin-left': '20px',
                  'margin-bottom': '50px',
                  width: 'calc(25% - 20px)',
                },
                img: {
                  height: 'calc(100% - 15px)',
                  position: 'absolute',
                  left: '0',
                  right: '0',
                  top: '0',
                },
                imgWrapper: {
                  'padding-top': 'calc(75% + 15px)',
                  position: 'relative',
                  height: '0',
                },
              },
              title: {
                'font-family': 'Ubuntu, sans-serif',
              },
              button: {
                'font-family': 'Ubuntu, sans-serif',
                ':hover': {
                  'background-color': '#b39e72',
                },
                'background-color': '#c7b07f',
                ':focus': {
                  'background-color': '#b39e72',
                },
                'border-radius': '9px',
                'padding-left': '26px',
                'padding-right': '26px',
              },
              price: {
                'font-family': 'Ubuntu, sans-serif',
              },
              compareAt: {
                'font-family': 'Ubuntu, sans-serif',
              },
              unitPrice: {
                'font-family': 'Ubuntu, sans-serif',
              },
            },
            buttonDestination: 'modal',
            contents: {
              options: false,
            },
            text: {
              button: 'Skoða vöru',
              outOfStock: 'Uppselt',
              unavailable: 'Ekki í boði eins og er',
            },
            googleFonts: [
              'Ubuntu',
            ],
          },
          productSet: {
            styles: {
              products: {
                '@media (min-width: 601px)': {
                  'margin-left': '-20px',
                },
              },
            },
          },
          modalProduct: {
            contents: {
              img: false,
              imgWithCarousel: true,
              button: false,
              buttonWithQuantity: true,
            },
            styles: {
              product: {
                '@media (min-width: 601px)': {
                  'max-width': '100%',
                  'margin-left': '0px',
                  'margin-bottom': '0px',
                },
              },
              button: {
                'font-family': 'Ubuntu, sans-serif',
                ':hover': {
                  'background-color': '#b39e72',
                },
                'background-color': '#c7b07f',
                ':focus': {
                  'background-color': '#b39e72',
                },
                'border-radius': '9px',
                'padding-left': '26px',
                'padding-right': '26px',
              },
              title: {
                'font-family': 'Ubuntu, sans-serif',
                'font-weight': 'bold',
                'font-size': '26px',
                color: '#4c4c4c',
              },
              price: {
                'font-family': 'Ubuntu, sans-serif',
                'font-weight': 'normal',
                'font-size': '18px',
                color: '#4c4c4c',
              },
              compareAt: {
                'font-family': 'Ubuntu, sans-serif',
                'font-weight': 'normal',
                'font-size': '15.299999999999999px',
                color: '#4c4c4c',
              },
              unitPrice: {
                'font-family': 'Ubuntu, sans-serif',
                'font-weight': 'normal',
                'font-size': '15.299999999999999px',
                color: '#4c4c4c',
              },
              description: {
                'font-family': 'Ubuntu, sans-serif',
              },
            },
            googleFonts: [
              'Ubuntu',
            ],
            text: {
              button: 'Setja í körfu',
              outOfStock: 'Uppselt',
              unavailable: 'Ekki í boði eins og er',
            },
          },
          option: {
            styles: {
              label: {
                'font-family': 'Ubuntu, sans-serif',
              },
              select: {
                'font-family': 'Ubuntu, sans-serif',
              },
            },
            googleFonts: [
              'Ubuntu',
            ],
          },
          cart: {
            styles: {
              button: {
                'font-family': 'Ubuntu, sans-serif',
                ':hover': {
                  'background-color': '#b39e72',
                },
                'background-color': '#c7b07f',
                ':focus': {
                  'background-color': '#b39e72',
                },
                'border-radius': '9px',
              },
            },
            text: {
              title: 'Karfa',
              total: 'Til greiðslu',
              empty: 'Karfan þín er tóm',
              notice: 'Aðrar upplýsingar um heimsendingu eru settar inn við greiðslu.',
              button: 'Ganga frá pöntun',
              outOfStock: 'Uppselt',
              unavailable: 'Ekki í boði eins og er',
              noteDescription: 'Hér er hægt að tilgreina hvaða flutningaðila skal nota ef senda þarf utan þess svæðis sem hefðbundin heimsending býður upp á. Sjá skilmála um afhendingu á vörum.',
            },
            contents: {
              note: true,
            },
            googleFonts: [
              'Ubuntu',
            ],
          },
          toggle: {
            styles: {
              toggle: {
                'font-family': 'Ubuntu, sans-serif',
                'background-color': '#c7b07f',
                ':hover': {
                  'background-color': '#b39e72',
                },
                ':focus': {
                  'background-color': '#b39e72',
                },
              },
            },
            googleFonts: [
              'Ubuntu',
            ],
          },
        },
      });
    });
  }
}
