import {
  fetchData, element, el, htmlToElement,
} from './utils';

function createDogBox(dog, parentEl) {
  const kross = dog.isAlive === 'TRUE' ? '' : '✞ ';
  const box = element('a', { class: 'col col-3 col-md-4 col-sm-10 offset-col-sm-1 dog-box', href: `./about.html?dog=${dog.id}` }, null,
    element('div', { class: 'dog-box_image' }, null,
      element('img', { src: `../img/${dog.profilePic}`, alt: dog.name }, null, dog.name)),
    element('div', { class: 'dog-box_text' }, null,
      el('p', dog.secondaryName),
      el('h3', kross + dog.name)));
  parentEl.appendChild(box);
}

export async function createDogList() {
  const dogs = await fetchData('../data/dogs.json');
  const dogContainer = document.querySelector('#dogs-container');
  dogContainer.textContent = '';
  dogs.dogs.forEach((dog) => {
    createDogBox(dog, dogContainer);
  });
}

/**
 * Fall sem tekur inn dagsetningum í millisekúndum og skilar
 * streng sem segir hve langt er síðan þessi dagsetning var.
 *
 * @param {number} time dagsetning í millisekúndum
 */
function calculateDogAge(dateBorn) {
  const now = new Date();
  const timeSince = now - dateBorn;
  let formattedtime;

  if (timeSince > 3.154e10) {
    const result = Math.floor(timeSince / 3.154e10);
    if (result % 10 === 1 && result !== 11) {
      formattedtime = `${result} árs `;
    } else {
      formattedtime = `${result} ára `;
    }
    if (timeSince - result > 2.628e9) {
      const monthResult = Math.floor((timeSince - (result * 3.154e10)) / 2.628e9);
      if (monthResult > 0) formattedtime += ` og ${monthResult} mánaða `;
    }
  } else if (timeSince > 2.628e9) {
    const result = Math.floor(timeSince / 2.628e9);
    formattedtime = `${result} mánaða `;
  } else if (timeSince > 8.64e7) {
    const result = Math.floor(timeSince / 8.64e7);
    formattedtime = `${result} daga `;
  }

  return formattedtime;
}

export async function createDogPage(id) {
  const dogs = await fetchData('../data/dogs.json');
  const dog = dogs.dogs[id];
  let kross = '';

  if (dog.isAlive === 'FALSE') {
    document.querySelector('#in-memory').textContent = 'Til minningar um';
    document.querySelector('#name').classList.add('in-memory');
    kross = '✞ ';
  }

  document.title = dog.name;
  document.getElementById('link-title').setAttribute('content', dog.name);
  document.getElementById('link-image').setAttribute('content', `../img/${dog.profilePic}`);
  document.getElementById('link-url').setAttribute('content', `https://hrafnsvik.is/dogs/about.html?dog=${id}`);
  document.querySelector('#profile-pic').setAttribute('src', `../img/${dog.profilePic}`);
  document.querySelector('#profile-pic').setAttribute('alt', dog.name);
  document.querySelector('#sex').textContent = dog.sex;
  document.querySelector('#name').textContent = kross + dog.name;
  document.querySelector('#secondary-name').textContent = dog.secondaryName;
  document.querySelector('.father .parent-name').textContent = dog.father;
  document.querySelector('.mother .parent-name').textContent = dog.mother;

  let { linkToFather, linkToMother } = dog;
  if (linkToFather !== undefined) {
    if (!linkToFather.includes('http')) {
      linkToFather = `../dogs/about.html?dog=${linkToFather}`;
    } else document.querySelector('.father').setAttribute('target', '_blank');
    document.querySelector('.father').setAttribute('href', linkToFather);
    document.querySelector('.father').classList.add('parent-link');
  }
  if (linkToMother !== undefined) {
    if (!linkToMother.includes('http')) {
      linkToMother = `../dogs/about.html?dog=${linkToMother}`;
    } else document.querySelector('.mother').setAttribute('target', '_blank');
    document.querySelector('.mother').setAttribute('href', linkToMother);
    document.querySelector('.mother').classList.add('parent-link');
  }

  let born;
  let old;
  if (dog.sex === 'Tík') {
    born = 'Fædd ';
    old = ' gömul';
  } else {
    born = 'Fæddur ';
    old = ' gamall';
  }
  document.querySelector('#birthdate').textContent = born + dog.birthdate;
  const dateBorn = new Date(dog.birthdate.substring(6, 10),
    parseInt(dog.birthdate.substring(3, 6), 10) - 1, dog.birthdate.substring(0, 3));
  if (dog.isAlive === 'TRUE') {
    document.querySelector('#age').textContent = calculateDogAge(dateBorn) + old;
  }

  document.querySelector('#about-dog').textContent = dog.about;

  const { HD, ED, eyeTest } = dog;
  let prev = document.querySelector('#health');
  if (HD !== undefined) {
    const p = el('p', `HD: ${HD}`);
    prev.insertAdjacentElement('afterend', p);
    prev = p;
  }
  if (ED !== undefined) {
    const p = el('p', `ED: ${ED}`);
    prev.insertAdjacentElement('afterend', p);
    prev = p;
  }
  if (eyeTest !== undefined) {
    const p = el('p', `Augnskoðun: ${eyeTest}`);
    prev.insertAdjacentElement('afterend', p);
  }

  const {
    PRA, EIC, CNM, SD2,
  } = dog;
  const dnaList = document.querySelector('#dna');
  if (PRA !== undefined) dnaList.appendChild(el('li', `PRA: ${PRA}`));
  if (EIC !== undefined) dnaList.appendChild(el('li', `EIC: ${EIC}`));
  if (CNM !== undefined) dnaList.appendChild(el('li', `CNM: ${CNM}`));
  if (SD2 !== undefined) dnaList.appendChild(el('li', `SD2: ${SD2}`));

  if (dog.achievements !== undefined) {
    const achievementsList = document.querySelector('#achievements');
    const achievements = dog.achievements.split(', ');
    achievements.forEach((achievement) => {
      achievementsList.appendChild(el('li', achievement));
    });
  } else document.querySelector('#achievements-header').classList.add('hidden');

  if (dog.aettbok !== undefined) {
    document.querySelector('#aettbok').setAttribute('href', dog.aettbok);
  } else {
    document.querySelector('#aettbok').setAttribute('style', 'display: none;');
  }

  if (dog.pictures === undefined && dog.videos === undefined) {
    document.querySelector('.pictures-title').textContent = '';
  }

  const galleryEl = document.querySelector('.dog-gallery');
  if (dog.pictures !== undefined) {
    const pictures = dog.pictures.split(', ');
    pictures.forEach((pic) => {
      const portrait = pic.includes('-p') ? 'portrait' : '';
      galleryEl.appendChild(
        element('div', { class: 'col col-6 col-sm-10 offset-col-sm-1' }, null,
          element('a', { class: 'img-container tooltip', href: `../img/${pic}`, 'data-lightbox': dog.name }, null,
            element('img', { class: portrait, src: `../img/${pic}` }, null, pic),
            element('span', { class: 'tooltiptext' }, null, 'Smelltu á myndina til að stækka'))),
      );
    });
  }
  if (dog.videos !== undefined) {
    const videos = dog.videos.split('-&-');
    videos.forEach((vid) => {
      const iFrameEl = htmlToElement(vid);
      galleryEl.appendChild(
        element('div', { class: 'col col-6 col-sm-10 offset-col-sm-1' }, null,
          element('div', { class: 'facebook-responsive' }, null, iFrameEl)),
      );
    });
  }
}
