import { createDogList, createDogPage } from './lib/dogs';
import setUpStore from './lib/store';

document.addEventListener('DOMContentLoaded', async () => {
  const currentUrl = window.location.href;
  if (currentUrl.includes('/dogs/about.html?')) {
    const dogId = currentUrl.substring(currentUrl.indexOf('dog=') + 4);
    createDogPage(dogId);
  } else if (currentUrl.includes('/dogs')) {
    createDogList();
  }
  if (currentUrl.includes('/store')) {
    setUpStore();
  }

  document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
    // eslint-disable-next-line func-names
    anchor.addEventListener('click', function (e) {
      e.preventDefault();
      document.querySelector(this.getAttribute('href')).scrollIntoView({
        behavior: 'smooth',
      });
    });
  });
});
